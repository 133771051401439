require('dotenv').config();
import { Option } from '@/types/Form';
import { HttpCode, HttpMethod } from '@/types/Model';
import { PhoneNumberUtil } from 'google-libphonenumber';

export const TEXT_ACCOUNT_FORM_TYPE_SECTION = 'Permissions';
export const TEXT_ACCOUNT_FORM_MANAGEMENT_SECTION = 'Change Password';
export const MSG_ACCOUNT_FORM_MANAGEMENT_SECTION =
  "Use this section to change your password";
export const MSG_GRANTING_PERMISSION =
  'Granting the owner permission grants all other permissions, and they cannot be toggled off';
export const MSG_GRANTING_PERMISSION_EDIT =
  'One or more permissions have been disabled as you do not have permission to grant or modify them';
export const TEXT_PEOPLE_FORM_GENERAL_SECTION = 'General';
export const TEXT_PEOPLE_FORM_UNION_SECTION = 'Union';
export const TEXT_PEOPLE_FORM_ADDRESS_SECTION = 'Address';

export const MESSAGE_CAMPAIGN_SMS_ALL =
  'Confirm that you want to send the message to all the people associated with the drive having a phone number. People who have opted out, will not be messaged.';
export const MESSAGE_CAMPAIGN_SMS_REMAINING =
  'Confirm that you want to send the message to the people associated with the drive who have not been sent it. People who have opted out, will not be messaged.';
export const MESSAGE_CAMPAIGN_SMS_SELECTED =
  'Confirm that you want to send the message to selected people. People who have opted out, will not be messaged.';
export const MESSAGE_CAMPAIGN_FINALIZE =
  'Confirm that you want to finalize / close the campaign. You will NOT be able to send any more messages against this campaign.';
export const MESSAGE_DRIVE_UNAPPROVE =
  'Un-approving this drive will stop signatures from being collected, are you sure you wan to un-approve this drive?';

export const ARRAY_PROVINCE_LIST: Option[] = [];

export const ARRAY_NOTES_USER_ACCESS: Option[] = [];

export const ARRAY_DRIVE_PERSON_LIST: Option[] = [];

export const ARRAY_STATUS: Option[] = [
  { label: 'True', value: 'true' },
  { label: 'False', value: 'false' },
];

export const ARRAY_PEOPLE_TARGET: Option[] = [];

export const ARRAY_PEOPLE_STATUS: Option[] = [
  { label: 'Friendly', value: 'friendly' },
  { label: 'Friendly Community Leader', value: 'friendly_comm_leader' },
  { label: 'Unknown', value: 'unknown' },
  { label: 'Hostile Community Leader', value: 'hostile_comm_leader' },
  { label: 'Hostile', value: 'hostile' },
];

export const CAMPAIGN_ACTION = {
  DRAFT: '1',
  FINALIZE: '2',
  SMS_SELF: '3',
  SMS_OWNERS: '4',
  SELECTED_PEOPLE: '5',
  ALL_PEOPLE: '6',
  NOT_SENT_PEOPLE: '7',
};

export const ARRAY_CAMPAIGN_ACTIONS_SMS_ALL: Option[] = [
  { label: 'SMS Self', value: CAMPAIGN_ACTION.SMS_SELF },
  { label: 'SMS Owners', value: CAMPAIGN_ACTION.SMS_OWNERS },
  { label: 'SMS Selected People', value: CAMPAIGN_ACTION.SELECTED_PEOPLE },
  { label: 'SMS All People', value: CAMPAIGN_ACTION.ALL_PEOPLE },
  { label: 'SMS People Not Sent To', value: CAMPAIGN_ACTION.NOT_SENT_PEOPLE },
];

export const ARRAY_CAMPAIGN_ACTIONS_SMS_OWNERS: Option[] = [
  { label: 'SMS Self', value: CAMPAIGN_ACTION.SMS_SELF },
  { label: 'SMS Owners', value: CAMPAIGN_ACTION.SMS_OWNERS },
];

export const TYPE_SMS = '1';
export const TYPE_MMS = '2';

export const MAX_MEDIA_ALLOWED = 7;
export const MAX_MEDIA_FILE_SIZE = 750; //Max file size in KB

export const ARRAY_REPORT_DRIVE: Option[] = [];

export const ARRAY_API_KEYS: any = [
  { value: 'OESystemAPIKey', label: 'OE System API Key' },
  { value: 'OEMobileKey', label: 'OE Mobile Key' },
];

export const ARRAY_DRIVE_TARGET: Option[] = [];

export const ARRAY_DRIVE_ACCESSIBLE_BY_LIST: Option[] = [];

export const ARRAY_CARD_UNIT_OPTIONS = ['Days', 'Months', 'Years'];

export const PERSON_STATES = {
  Friendly: '82592EF0-FA84-4640-879A-B9991776651A',
  FriendlyCommunityLeader: 'DBE119C1-74F6-4C4A-A098-86FD7948F365',
  HostileCommunityLeader: '6946E4A1-495E-44F3-83B7-306ED0E674B2',
  Hostile: '71D5132B-3EE4-47F5-A887-72A749B0059D',
  Unknown: '2CCFC596-FBA8-4590-A8EE-948DE34060FF',
};

/**
 * Tenant settings key names
 */
export const TENANT_SETTINGS_KEY_LOGO = 'Logo';
export const TENANT_SETTINGS_KEY_HEADERIMAGE = 'HeaderImage';
export const TENANT_SETTINGS_KEY_FOOTERIMAGE = 'FooterImage';
export const TENANT_SETTINGS_KEY_QRCODELOGO = 'QRCodeLogo';
export const TENANT_SETTINGS_KEY_SMSCOST = 'SMSSegmentCost';
export const TENANT_SETTINGS_KEY_MMSCOST = 'MMSSegmentCost';
export const TENANT_SETTINGS_KEY_TEANANTID = 'TenantId';
export const TENANT_SETTINGS_KEY_SERVERURL = 'ServerURL';
export const SETTINGS_SMS_SEGEMENT_LENGTH = 160.0;

export const TEXT_FIRST_DRIVE_REPORT: string =
  'This report returns the information on a specific drive, based on the filters selected.';
export const TEXT_SECOND_DRIVE_REPORT: string =
  'Select a drive from the dropdown below, then select the data filters. At least one data filter must be selected';
export const TEXT_CARD_EXPORT_REPORT: string =
  'This report returns the signatures collected via the signature portal.';
export const TEXT_EXPIRY_CARD_REPORT: string =
  'This report returns all the signatures set to expire on or after the date input';


export const GUEST_CANNOT_CREATE_EDIT_DRIVE='Guests cannot create drives. Please contact support@unionx.app to arrange for your own account.'
export const DRIVE_SETTINGS_KEY_LOGO = 'Logo';
export const DRIVE_SETTINGS_KEY_HEADERIMAGE = 'Header';
export const DRIVE_SETTINGS_KEY_FOOTERIMAGE = 'Footer';
export const DRIVE_SETTINGS_KEY_QRCODELOGO = 'QRCodeLogo';

/**
 * Client Routes
 */
export const ROUTE_CLIENT_AUTH_LOGIN = '/login';
export const ROUTE_EDIT_RAID: string = '/dashboard/raid/edit/';
export const ROUTE_EDIT_ACCOUNT: string = '/dashboard/account/edit/';
export const ROUTE_EDIT_PEOPLE: string = '/dashboard/people/edit/';
export const ROUTE_EDIT_TARGET: string = '/dashboard/target/edit/';
export const ROUTE_EDIT_NOTES: string = '/dashboard/note/edit/';
export const ROUTE_CREATE_NOTES_PERSON: string = '/dashboard/note/create/person/';
export const ROUTE_EDIT_DRIVE: string = '/dashboard/drive/edit/';
export const ROUTE_CREATE_TARGET: string = '/dashboard/target/create';
export const ROUTE_RAID_CREATE: string = '/dashboard/raid/create';
export const ROUTE_RAID_LIST: string = '/dashboard/raid';
export const ROUTE_ACCOUNT_LIST: string = '/dashboard/account';
export const ROUTE_PEOPLE_CREATE: string = '/dashboard/people/create';
export const ROUTE_PEOPLE_IMPORT: string = '/dashboard/people/import';
export const ROUTE_MEDIA_NOTE: string = '/dashboard/note/media';
export const ROUTE_DRIVE_CREATE: string = '/dashboard/drive/create';
export const ROUTE_ACCOUNT_CREATE: string = '/dashboard/account/create';
export const ROUTE_API_KEYS_LIST: string = '/dashboard/api-keys';
export const ROUTE_TARGET_LIST: string = '/dashboard/target';
export const ROUTE_PEOPLE_LIST: string = '/dashboard/people';
export const ROUTE_NOTE_LIST: string = '/dashboard/note';
export const ROUTE_NOTE_CREATE: string = '/dashboard/note/create';
export const ROUTE_DRIVE_LIST: string = '/dashboard/drive';
export const ROUTE_LOGOUT: string = '/logout';
export const ROUTE_CLIENT_PAYMENT_PAGE = '/signaturepayment';
export const ROUTE_NOTE_COMMENT_HISTORY: string = '/dashboard/note/comment/history';
export const ROUTE_BRANDING_EDIT: string = '/dashboard/branding/edit';
export const ROUTE_OESYSTEM_LIST: string = '/dashboard/oesystem';
export const ROUTE_EDIT_OESYSTEM: string = '/dashboard/oesystem/edit';
export const ROUTE_MEDIA_LIBRARY_CREATE: string = '/dashboard/media-library/create';
export const ROUTE_MEDIA_LIBRARY_EDIT: string = '/dashboard/media-library/edit';
export const ROUTE_MEDIA_LIBRARY_LIST: string = '/dashboard/media-library';
export const ROUTE_VIEW_MESSAGE: string = '/dashboard/messages/view/';
export const ROUTE_MESSAGE_LIST: string = '/dashboard/messages';
export const ROUTE_SCHEDULED_MESSAGE_LIST: string = '/dashboard/messages/scheduled';
export const ROUTE_MEDIA_LIBRARY_SELECTION: string = '/dashboard/media-library/selection';
export const ROUTE_CAMPAIGN_CREATE: string = '/dashboard/message-campaign/create';
export const ROUTE_CAMPAIGN_EDIT: string = '/dashboard/message-campaign/edit';
export const ROUTE_CAMPAIGN_LIST: string = '/dashboard/message-campaign';
export const ROUTE_CAMPAIGN_PEOPLE: string = '/dashboard/message-campaign/people';
export const ROUTE_MESSAGE_TEMPLATE_CREATE: string = '/dashboard/message-template/create';
export const ROUTE_MESSAGE_TEMPLATE_EDIT: string = '/dashboard/message-template/edit';
export const ROUTE_MESSAGE_TEMPLATE_LIST: string = '/dashboard/message-template';
export const ROUTE_SMSPHONE_LIST: string = '/dashboard/sms-phone';
export const ROUTE_SMSPHONE_CREATE: string = '/dashboard/sms-phone/create';
export const ROUTE_SMSPHONE_EDIT: string = '/dashboard/sms-phone/edit';
export const ROUTE_VIEW_MESSAGE_SCHEDULE: string = '/dashboard/messages/scheduled/view/';
export const ROUTE_TOUCHPOINT_LIST: string = '/dashboard/touch-point';
export const ROUTE_TOUCHPOINT_CREATE: string = '/dashboard/touch-point/create';
export const ROUTE_TOUCHPOINT_EDIT: string = '/dashboard/touch-point/edit';
export const ROUTE_TENANT_LIST: string = '/dashboard/tenant';
export const ROUTE_TENANT_CREATE: string = '/dashboard/tenant/create';
export const ROUTE_TENANT_EDIT: string = '/dashboard/tenant/edit';

export const TEXT_NEW_API_KEY: string =
  'Use the drop-down to select which type of key you would like. By default the OE System API key will be generated.';

export const ARRAY_DRIVE = [];

export const ALLOWED_CALLBACKS: string[] =
  process.env.NEXT_PUBLIC_ALLOWED_CALLBACKS?.split(',') || [];

export const ROUTE_SERVER_LOGIN_USER = '/api/v1/auth/login';
export const SERVER_URL = process.env.SERVER_URL || '';
export const ENVIRONMENT = process.env.ENVIRONMENT || 'development';
export const UX_TENANT = process.env.UX_TENANT || '31337016-1830-3398-8746-183313374618';
export const UXI_GOOGLE_ANALYTICS_GA4_ID = process.env.UXI_GOOGLE_ANALYTICS_GA4_ID || 'G-EBZNBPYRJ9';

/**
 * Request status codes
 */
export const STATUS_OK: HttpCode = 200;
export const STATUS_BAD_REQUEST: HttpCode = 400;
export const STATUS_UNAUTHORIZED: HttpCode = 401;
export const STATUS_FORBIDDEN: HttpCode = 403;
export const STATUS_NOT_FOUND: HttpCode = 404;
export const STATUS_METHOD_NOT_ALLOWED: HttpCode = 405;
export const STATUS_UNPROCESSABLE_ENTITY: HttpCode = 422;

/**
 * Server API Routes (Next)
 */
export const ROUTE_SERVER_NEXT_LOGIN = '/api/v1/auth/login';
export const ROUTE_SERVER_NEXT_GET_USER = '/api/auth/user';
export const ROUTE_SERVER_NEXT_LOGOUT = '/api/auth/logout';
export const ROUTE_SERVER_NEXT_COMMON = '/api/common';
export const ROUTE_SERVER_NEXT_GET_RAIDS = '/api/v1/agreement';
export const ROUTE_SERVER_NEXT_GET_API_KEYS = '/api/v1/apikeys';
export const ROUTE_SERVER_NEXT_TOGGLE_API_KEYS = '/api/v1/apikeys/toggle';
export const ROUTE_SERVER_NEXT_GET_PEOPLES = '/api/v1/persons';
export const ROUTE_SERVER_NEXT_POST_ADD_MANUAL_CARD = '/api/v1/signature/signedCard';

export const ROUTE_SERVER_NEXT_GET_PEOPLES_BY_DRIVE = '/api/v1/persons/drive';
export const ROUTE_SERVER_NEXT_GET_PEOPLES_SELECTION = '/api/v1/persons/selection';
export const ROUTE_SERVER_NEXT_GET_PEOPLE_TEMPLATE = '/api/v1/persons/export/template';
export const ROUTE_SERVER_NEXT_PEOPLE_IMPORT = '/api/v1/persons/import';
export const ROUTE_SERVER_NEXT_GET_TARGETS = '/api/v1/company';
export const ROUTE_SERVER_NEXT_GET_TARGETS_SELECTION = '/api/v1/company/selection';
export const ROUTE_SERVER_NEXT_GET_DRIVES = '/api/v1/drives';
export const ROUTE_SERVER_NEXT_GET_DRIVE_APPROVAL = '/api/v1/drives/approval';
export const ROUTE_SERVER_NEXT_GET_DRIVES_SELECTION = '/api/v1/drives/selection';
export const ROUTE_SERVER_NEXT_GET_DRIVES_WITHPHONE_SELECTION =
  '/api/v1/drives/selection/withphone';

export const ROUTE_SERVER_NEXT_GET_MAP_API_KEY = '/api/v1/drives/apikey';
export const ROUTE_SERVER_NEXT_GET_PEOPLE_LOCATION_LIST = '/api/v1/drives/getdrivepeoplelocation';

export const ROUTE_SERVER_NEXT_GET_LIST_PROVINCE = '/api/v1/list/provinces';
export const ROUTE_SERVER_NEXT_GET_LIST_STATE_PROVINCE = '/api/v1/list/stateorprovincesbycountry';
export const ROUTE_SERVER_NEXT_GET_LIST_PERSON_STATUS = '/api/v1/list/personstatuses';
export const ROUTE_SERVER_NEXT_GET_USERS = '/api/v1/users';
export const ROUTE_SERVER_NEXT_GET_USERS_NOTIFICATIONS = '/api/v1/users/notificationsetting';
export const ROUTE_SERVER_NEXT_GET_USERS_SELECTION = '/api/v1/users/selection';
export const ROUTE_SERVER_NEXT_USERS_DELETE = '/api/v1/users/delete';
export const ROUTE_SERVER_NEXT_USERS_DISABLE = '/api/v1/users/disable';
export const ROUTE_SERVER_NEXT_USERS_RESETPASSWORD = '/api/v1/users/resetpasswordemail';
export const ROUTE_SERVER_NEXT_GET_SIGNATURE_TEXT =
  '/api/v1/tenants/settings?key=SignatureDeclarationText';
export const ROUTE_SIGNATURE_SIGNUP = '/api/v1/signature/signup';
export const ROUTE_SIGN_TEMPLATE = '/api/v1/signature/template';
export const ROUTE_SIGN_VERIFICATION = '/api/v1/signature/signatureverify';
export const ROUTE_SIGN_PAYMENTDETAILS = '/api/v1/signature/paymentdetails';
export const ROUTE_SIGN_SESSION = '/api/v1/signature/paymentsession';
export const ROUTE_SIGN_PAYMENT = '/api/v1/signature/payment';
export const ROUTE_CARD_APPROVAL = '/api/v1/signature/cardapproval';
export const ROUTE_SERVER_NEXT_UPDATE_USER_PASSWORD = '/api/v1/users/password';
export const ROUTE_SERVER_NEXT_UPDATE_USER_NOTIFICATIONS = '/api/v1/users/notificationsetting';
export const ROUTE_SERVER_NEXT_FORGET_PASSWORD = '/api/v1/users/forgotpassword';
export const ROUTE_SERVER_NEXT_RESET_PASSWORD = '/api/v1/users/resetpassword';
export const ROUTE_SERVER_NEXT_GET_NOTES = '/api/v1/notes';
export const ROUTE_SERVER_NEXT_GET_PERSON_SEARCH = '/api/v1/persons/search';
export const ROUTE_SERVER_NEXT_GET_PERSON_OESEARCH = '/api/v1/persons/oesystem';
export const ROUTE_SERVER_NEXT_GET_PERSON_OECOMMENT = '/api/v1/persons/oesystem/comment';
export const ROUTE_SERVER_NEXT_GET_PERSON_CARD_DETAILS = '/api/v1/signature/carddetails';
export const ROUTE_SERVER_NEXT_GET_DRIVE_FOR_PERSON = '/api/v1/drives/drivesforperson';
export const ROUTE_SERVER_NEXT_GET_IS_PAYMENT_REQUIRED = '/api/v1/drives/isPaymentRequired';
export const ROUTE_SERVER_NEXT_POST_DRIVE_IMAGE = '/api/v1/drives/image';
export const ROUTE_SERVER_NEXT_PUT_DRIVE_IMAGE_DELETE = '/api/v1/drives/image/delete';
export const ROUTE_SERVER_NEXT_GET_SIGN_INVITE = '/api/v1/signature/invite';
export const ROUTE_SERVER_NEXT_GET_DRIVE_DETAIL_REPORT = '/api/v1/report/drive-export';
export const ROUTE_SERVER_NEXT_GET_SIGN_DETAIL_REPORT = '/api/v1/report/signature-export';
export const ROUTE_SERVER_NEXT_GET_EXPIRY_DETAIL_REPORT = '/api/v1/report/expiring-cards';
export const ROUTE_SERVER_NEXT_GET_DOCUMENT = '/api/v1/azurestorage/downloadbyid';
export const ROUTE_SERVER_NEXT_DELETE_DRIVES_PEOPLE = '/api/v1/drives/person';
export const ROUTE_SERVER_NEXT_POST_ASSIGN_ORGANIZER = '/api/v1/persons/assignpersons';
export const ROUTE_SERVER_NEXT_POST_AUTO_ASSIGN_ORGANIZER = '/api/v1/persons/autoassignpersons';
export const ROUTE_SERVER_NEXT_POST_ASSIGN_ORGANIZER_EQUALLY = '/api/v1/persons/assignpersonsequally';
export const ROUTE_SERVER_NEXT_GET_BRANDING = '/api/v1/branding';
export const ROUTE_SERVER_NEXT_PUT_BRANDING_SETTING = '/api/v1/branding/setting';
export const ROUTE_SERVER_NEXT_POST_BRANDING_IMAGE = '/api/v1/branding/image';
export const ROUTE_SERVER_NEXT_GET_BRANDING_PARAMS = '/api/v1/branding/parameters';
export const ROUTE_SERVER_NEXT_GET_TEMPLATE_IMAGES = '/api/v1/azurestorage/downloadimage';
export const ROUTE_SERVER_NEXT_POST_AZURE_FILE = '/api/v1/azurestorage/upload';
export const ROUTE_SERVER_NEXT_GET_MEDIA_LIBRARY = '/api/v1/medialibrary';
export const ROUTE_SERVER_NEXT_GET_MESSAGE = '/api/v1/message';
export const ROUTE_SERVER_NEXT_GET_MEDIA_SELECTION = '/api/v1/medialibrary/selection';
export const ROUTE_SERVER_NEXT_GET_SMS_CAMPAIGN = '/api/v1/message/campaign';
export const ROUTE_SERVER_NEXT_GET_SMS_TEMPLATE = '/api/v1/message/template';
export const ROUTE_SERVER_NEXT_GET_PEOPLE_CAMPAIGN = '/api/v1/message/campaign/people';
export const ROUTE_SERVER_NEXT_GET_CAMPAIGN_DRIVES = '/api/v1/drives/selection';
export const ROUTE_SERVER_NEXT_GET_DOWNLOAD_MEDIA_LIBRARY = '/api/v1/azurestorage/downloadmedia';
export const ROUTE_SERVER_NEXT_ADD_MESSAGE = '/api/v1/message/send';
export const ROUTE_SERVER_NEXT_GET_MESSAGE_TEMPLATE = '/api/v1/message/template';
export const ROUTE_SERVER_NEXT_GET_SMSNUMBERS = '/api/v1/branding/smsphone';
export const ROUTE_SERVER_NEXT_GET_MESSAGE_SCHEDULE = '/api/v1/message/schedule';
export const ROUTE_SERVER_NEXT_CANCEL_MESSAGE_SCHEDULE = '/api/v1/message/schedule/cancel';
export const ROUTE_SERVER_NEXT_GET_LIST_TIMEZONE = '/api/v1/list/timezones';
export const ROUTE_SERVER_NEXT_GET_TOUCH_POINT = '/api/v1/touchpoint';
export const ROUTE_SERVER_NEXT_GET_TOUCH_POINT_TYPES = '/api/v1/list/touchpointtypes';
export const ROUTE_SERVER_NEXT_GET_TOUCH_POINT_SIGN_STATES = '/api/v1/list/signStates';
export const ROUTE_SERVER_NEXT_GET_TOUCH_POINT_SIGN_SENTIMENTS = '/api/v1/list/sentiments';
export const ROUTE_SERVER_NEXT_GET_MODULES_OPTINAL = '/api/v1/list/modules/optional';
export const ROUTE_SERVER_NEXT_GET_TENANT = '/api/v1/tenants';


/**
 * Card Status
 */
export const CARD_STATUS_VALID = 'Valid';
export const CARD_STATUS_EXPIRED = 'Expired';
export const CARD_STATUS_WILLEXPIRE = 'Expire in 7 days';
export const CARD_STATUS_NOINVITE = 'No Invite';

/**
 * Privileges
 */
export const PRIVILEGES = {
  IsOwner: 'IsOwner',
  IsGust: 'IsGuest',
  Drives: 'CanManageDrives',
  Billing: 'CanManageBilling',
  Accounts: 'CanManageAccounts',
  Raids: 'CanManageRaids',
  Reports: 'CanManageReports',
  Notes: 'CanManageNotes',
  People: 'CanManagePeople',
  Compney: 'CanManageCompany',
  APIKeys: 'CanManageAPIKeys',
  OESystem: 'CanManageOESystem',
  ManageSMS: 'CanManageSMS',
  CreateSMS: 'CanCreateSMS',
  ApproveSMS: 'CanApproveSMS',
  TouchPoint: 'CanManageTouchPoint',
  TouchPointAdmin: 'TouchPointAdmin',
};

export const GRID_ROWS = 25;

/**
 * Validation Messages
 */
export const MSG_UNEXPECTED_ERR = 'An unexpected error occurred! Please try again later.';
export const MSG_EMAIL_REQUIRED = 'The email field is required';
export const MSG_PASSWORD_REQUIRED = 'The password field is required';
export const MSG_BAD_REQUEST = 'The request body is badly formatted';
export const MSG_UNAUTHORIZED = 'You are not authorized to perform the requested action';
export const MSG_NO_FILE_FOUND = 'Please attach the file to be uploaded';
export const ALLOWED_METHODS_NEXT_COMMON: HttpMethod[] = ['GET', 'POST', 'PUT', 'PATCH', 'DELETE'];
export const MSG_NO_DRIVE_SELECTED =
  'No drives have been created. You need to create a drive to access this page.';


/**
* Number validations
*/
const phoneUtil = PhoneNumberUtil.getInstance();

export const isValidPhoneNumber = (phoneNumber: string) => {
    try {
      const number = phoneUtil.parseAndKeepRawInput(phoneNumber, 'CA'); // Default to CA, but works for international numbers
      return phoneUtil.isValidNumber(number);
    } catch (e) {
      return false;
    }
  };

/**
* Email & Number validations
*/
export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const restrictedPrefixes = ['911', '811', '311', '411'];

/**
 * Constants for query keys and API route
 */

export const QUERY_KEY_GET_RAIDS = 'getRaids';
export const QUERY_KEY_GET_PEOPLES = 'getPeoples';
export const QUERY_KEY_GET_PEOPLES_SELECTION = 'getPeoplesSelection';
export const QUERY_KEY_GET_TARGET = 'getCompanies';
export const QUERY_KEY_GET_TARGET_SELECTION = 'getCompaniesSelection';
export const QUERY_KEY_GET_PROVINCE = 'getProvince';
export const QUERY_KEY_GET_PERSON_STATUS = 'getPersonStatus';
export const QUERY_KEY_GET_SIG_TEXT = 'getPersonSigText';
export const QUERY_KEY_GET_DRIVE = 'getDrives';
export const QUERY_KEY_GET_DRIVE_SELECTION = 'getDrivesSelection';
export const QUERY_KEY_GET_CAMPAIGN_DRIVE = 'getCampaignDrives';
export const QUERY_KEY_GET_USERS = 'getUsers';
export const QUERY_KEY_GET_USERS_SELECTION = 'getUserSelection';
export const QUERY_KEY_GET_SIGN_TEMPLATE = 'getSignTemplate';
export const QUERY_KEY_GET_SIGN_VERIFICATION = 'getSignVerification';
export const QUERY_KEY_GET_NOTES = 'getNotes';
export const QUERY_KEY_GET_PAYMENT_DETAILS = 'getPaymentDetails';
export const QUERY_KEY_GET_PAYMENT_POST = 'postPaymentTransaction';
export const QUERY_KEY_GET_PAYMENT_SESSION = 'getPaymentSession';
export const QUERY_KEY_GET_PAYMENT_STATUS = 'getPaymentStatus';
export const QUERY_KEY_GET_DRIVE_FOR_PEOPLES = 'getDriveForPerson';
export const QUERY_KEY_GET_DRIVE_IS_PAYMENT_REQUIRED = 'getDriveIsPaymentRequired';
export const QUERY_KEY_GET_RAID_BY_ID = 'getRaidByID';
export const QUERY_KEY_GET_API_KEYS_BY_ID = 'getApiKeysByID';
export const QUERY_KEY_GET_TARGET_BY_ID = 'getTargetByID';
export const QUERY_KEY_GET_PEOPLE_BY_ID = 'getPeopleByID';
export const QUERY_KEY_GET_PEOPLE_CARD_BY_ID = 'getPeopleCardByID';
export const QUERY_KEY_GET_DRIVE_BY_ID = 'getDriveByID';
export const QUERY_KEY_GET_MAP_API_KEY = 'getMapiKey';
export const QUERY_KEY_GET_USER_BY_ID = 'getUserByID';
export const QUERY_KEY_GET_USER_NOTIFICATION_TYPE_BY_ID = 'getNotificationTypeUserByID';
export const QUERY_KEY_GET_NOTE_BY_ID = 'getNoteByID';
export const QUERY_KEY_GET_COMMENT_HISTORY_NOTE_BY_ID = 'getNoteHistoryByID';
export const QUERY_KEY_GET_BRANDING = 'getBranding';
export const QUERY_KEY_GET_PARAMETERS = 'getParameters';
export const QUERY_KEY_GET_CARD_REPORT = 'getCardReports';
export const QUERY_KEY_GET_OESYSTEM = 'getOESystemSearch';
export const QUERY_KEY_GET_MEDIA_LIBRARY = 'getMediaLibrary';
export const QUERY_KEY_GET_MEDIA_SELECTION = 'getMediaLibrary';
export const QUERY_KEY_GET_OECOMMENT = 'getOEComments';
export const QUERY_KEY_GET_SERVER_URL = 'getServerURL';
export const QUERY_KEY_GET_MESSAGES = 'getMessage';
export const QUERY_KEY_GET_MESSAGE_BY_ID = 'getMessageById';
export const QUERY_KEY_GET_CAMPAIGN = 'getCampaignList';
export const QUERY_KEY_GET_CAMPAIGN_BY_Id = 'getCampaignByID';
export const QUERY_KEY_GET_CAMPAIGN_PEOPLE = 'getCampaignPeopleList';
export const QUERY_KEY_GET_SMS_TEMPLATE = 'getSMSTemplateList';
export const QUERY_KEY_GET_MESSAGE_TEMPLATE_BY_ID = 'getMessageTemplateByID';
export const QUERY_KEY_GET_MESSAGE_TEMPLATE = 'getMessageTemplate';
export const QUERY_KEY_GET_SMSPHONE = 'getSMSPhone';
export const QUERY_KEY_GET_SMSPHONE_BY_ID = 'getSMSPhoneByID';
export const QUERY_KEY_GET_TIMEZONE = 'getTimeZone';
export const QUERY_KEY_GET_TOUCHPOINT = 'getTouchPoint';
export const QUERY_KEY_GET_TOUCHPOINT_BY_ID = 'getTouchPointById';
export const QUERY_KEY_GET_TOUCHPOINTTYPE = 'getTouchPointType';
export const QUERY_KEY_GET_TOUCHPOINTSIGNSTATES = 'getTouchPointSignStates';
export const QUERY_KEY_GET_TOUCHPOINTSENTIMENTS = 'getTouchPointSentiments';
export const QUERY_KEY_GET_TENANT = 'getTenant';
export const QUERY_KEY_GET_TENANT_BY_ID = 'getTenantById';
export const QUERY_KEY_GET_MODULE = 'getModule';
export const QUERY_KEY_GET_STATEORPROVIENCE = 'stateorprovince';

export const AUTH_SETTINGS = Object.freeze({
  COOKIE_NAME_ACCESS: 'unappui_access',
  COOKIE_NAME_REFRESH: 'upappiui_refresh',
});

export const MODULE_SMS = 'DA910DD6-9E89-4678-95FB-741AF0A485DD';
export const MODULE_SIGNATURE_BRANDING = '1C65615F-AEE4-4FB3-9196-ED502C762E38';
